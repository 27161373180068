<template>
  <div class="add-product-container">
      <div class="container-title">
        <a-breadcrumb separator=">" class="breadcrumb">
            <a-breadcrumb-item>
            <a @click.prevent="$router.push('/index')">首页</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
            <a @click.prevent="$router.push(`/seller-content/store-manage?entityId=${$route.query.entityId}`)">商品管理</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
            商品详情
            </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="container">
        <a-card class="add-content">
            <a-row class="content">
                <a-col :span="3" class="right">卖家名称：</a-col>
                <a-col :span="12">
                    <a-input disabled :value="seller"></a-input>
                </a-col>
            </a-row>

            <a-card title="商品分类">
                <a-row type="flex" align="middle">
                    <a-col :span="24">
                        <!-- <Cascader :primaryList="classify.primary" :reclassify="classify.re" @change="change"/> -->
                    </a-col>

                    <a-col :span="24">
                        <a-alert class="cascader-alert">
                            <p slot="description">
                                <b>您当前选择的商品类别是：</b>
                                {{classify.primary + ' > ' + classify.re}}
                            </p>
                        </a-alert>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">分类属性</div>
                <a-row>
                    <a-col :span="24" v-for="(item,index) in detail.property" :key="item.id">
                        <a-col :span="3" class="label-left">
                            <span class="font-error" v-if="index === 0">*</span> {{item.label}}：
                        </a-col>
                        <a-col :span="11">
                            <a-select show-search value="1" disabled style="width: 240px" :placeholder="'请选择'+item.label">
                                <a-select-option key="1">
                                    {{ item.value }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col v-if="index === 0" :span="10" style="line-height: 32px">
                            <a-switch :checked="detail.isSpec"  disabled style="margin-right: 16px">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch>
                            <span>发票上是否显示该规格</span>
                        </a-col>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">发票信息</div>
                <a-row>
                    <a-col :span="3" class="label-left"><span class="font-error">*</span>品类名称：</a-col>
                    <a-col :span="8">
                        <a-select style="width: 100%" value="1" disabled placeholder="请选择品类名称">
                            <a-select-option key="1">
                                {{ detail.invoiceName }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="12" style="line-height: 32px;margin-left: 32px">
                        品类编码：{{detail.invoiceCode}}
                    </a-col>

                    <a-col :span="3" class="label-left">规格型号：</a-col>
                    <a-col :span="21" style="line-height: 32px">
                        {{detail.isSpec && detail.specName || ''}}
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">商品信息</div>
                <a-row>
                    <a-col :span="3" class="label-left"><span class="font-error">*</span>货品数量：</a-col>
                    <a-col :span="21">
                        <a-input-number :value="detail.quantity" disabled placeholder="请输入" style="width: 280px">
                        </a-input-number>
                        <div class="input-number-slot">
                             <span style="padding: 0 10px">{{detail.unitsMeasure | unit}}</span>
                        </div>
                    </a-col>

                    <a-col :span="3" class="label-left"><span class="font-error">*</span>货物总价：</a-col>
                    <a-col :span="21">
                        <a-input-number :value="detail.totalPrice" disabled style="width: 315px">
                        </a-input-number>
                        <div class="input-number-slot">
                            <span style="padding: 0 10px">元</span>
                        </div>
                    </a-col>

                    <a-col :span="6" class="label-c">货款单价：{{detail.unitPrice}} 元/{{detail.unitsMeasure | unit}}</a-col>
                    <a-col :span="6" class="label-c">代征税款： {{productInfo.witholding}} 元 </a-col>
                    <a-col :span="6" class="label-c">{{taxRate.policyName}}：{{productInfo.vat}} 元</a-col>
                    <a-col :span="6" class="label-c">合同总价：{{productInfo.totalPrices}} 元</a-col>

                    <a-col :span="3" class="label-left">商品照片：</a-col>
                    <a-col :span="21">
                        <div class="l-flex" style="margin-top: 10px">
                            <div v-for="(item, index) in detail.imgList" :key="index" class="product-img" :class="item.isMain &&'is-main'">
                                <img :src="item.imgPath" alt="">
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">地址信息</div>
                <a-row>
                    <!-- <a-col :span="4" class="label-left">请选择常用发货地：</a-col>
                    <a-col :span="20">
                        <a-select default-value="jack" style="width: 530px" disabled>
                            <a-select-option value="jack">
                                安徽省 宣城市 宁国市 宁国市畅翔耐磨材料有限公司
                            </a-select-option>
                            <a-select-option value="jacks">
                                安徽省 宣城市 宁国市 宁国市畅翔耐磨材料有限公司
                            </a-select-option>
                        </a-select>
                    </a-col> -->

                    <a-col :span="2" class="label-left">发货地：</a-col>
                    <a-col :span="10">
                        <a-cascader style="width: 320px" :field-names="{ value: 'key', label: 'label', children: 'children' }" :value="detail.cascader" :options="options" disabled placeholder="请选择" />
                    </a-col>
                    <a-col :span="12">
                        <a-input :value="detail.addrDetail" disabled placeholder="请输入详细地址"></a-input>
                    </a-col>

                    <a-col :span="2" class="label-left">备注：</a-col>
                    <a-col :span="22">
                        <a-textarea :value="detail.remark" disabled placeholder="请输入" :auto-size="{ minRows: 5 }" />
                    </a-col>
                </a-row>
            </a-card>

            <a-collapse activeKey="1">
                <a-collapse-panel key="1" header="高级设置">
                    <a-row>
                        <a-col :span="3" class="label-left">订单最低单价：</a-col>
                        <a-col :span="21">
                            <a-input-number :value="detail.minFloatUnitPrice" style="width: 280px" disabled :precision="2">
                            </a-input-number>
                            <div class="input-number-slot">
                                <span style="padding: 0 10px">元 / {{detail.unitsMeasure | unit}}</span>
                            </div>
                        </a-col>

                        <a-col :span="3" class="label-left">最小采购数量：</a-col>
                        <a-col :span="9">
                            <a-input-number :value="detail.minQuantity" placeholder="1" :min="1" disabled  />
                            <div class="input-number-slot">
                                <span style="padding: 0 10px"> {{detail.unitsMeasure | unit}} </span>
                            </div>
                        </a-col>
                        <a-col :span="3" class="label-left">最大采购数量：</a-col>
                        <a-col :span="9">
                            <a-input-number :value="detail.maxQuantity" style="width: 190px" disabled />
                            <div class="input-number-slot">
                                <span style="padding: 0 10px"> {{detail.unitsMeasure | unit}} </span>
                            </div>
                        </a-col>
                    </a-row>
                </a-collapse-panel>
            </a-collapse>

            <div class="issue-shop">
                <a-space>
                    <a-button @click="$router.replace(`/seller-content/store-manage?entityId=${$route.query.entityId}`)">返回</a-button>
                </a-space>
            </div>
        </a-card>
      </div>
  </div>
</template>

<script>
import {productDetail} from '@/api/seller.js'
import options from '@/utils/ara'
import {mapGetters} from 'vuex'
import { type } from "@/utils/constArr";
import {evaluation,queryRegionPolicy,secondFind} from '@/api/seller.js'
export default {
    data() {
        return {
            options,
            detail: {},
             // 税率
            taxRate: {},
            // 商品信息
            productInfo: {
                num: '',
                price: '',
                vat: 0,
                surcharge: 0,
                witholding: 0,
                totalPrices: 0
            },
            // 当前选择的商品类别
            classify: {
                primary: '',
                re: ''
            }
        }
    },
    filters: {
        unit:(val)=>type[val]
    },
    computed: {
        ...mapGetters(['getRoleS']),
        seller() {
            const obj = this.getRoleS.find(item => item.entityId == this.$route.query.entityId) || {}
            return obj.commercialName
        }
    },
    mounted() {
        queryRegionPolicy(this.$route.query.entityId).then(res => {
            if (!res.data || !res.data.length) {
                this.$message.error('卖家未绑定税费计算政策')
            } else {
                this.taxRate = res.data.find(item => item.policyName === '3%增值税') || res.data[0]
            }
            if (res.code !== 200) {
                this.$message.error(res.message)
                return
            }
            productDetail({merchandiseId: this.$route.query.id}).then(res => {
                console.log(res)
                const data = res.data
                const attributes = JSON.parse(data.attributes)
                let property=[]
                for(let i in attributes){
                    let obj={
                        label:i,
                        value:attributes[i]
                    }
                    property.push(obj)
                }
                data.property = property
                data.cascader = [data.pCode, data.cCode, data.aCode]
                this.detail = data
                this.getClassify()
                this.getTaxes()
            })
        })
    },
    methods: {
        // 获取税
        async getTaxes() {
            evaluation(this.$route.query.entityId, this.detail.totalPrice, this.taxRate.id).then(res => {
                const result = res.data[0] || {}
                this.productInfo.vat = result.vatAmount
                this.productInfo.witholding = result.taxAmount
                this.productInfo.totalPrices = result.contractAmount
            })
        },
        // 获取选中的分类
        getClassify() {
            secondFind(this.detail.categorySecondId).then(res => {
                this.classify.primary = res.data.catValue
                this.classify.re = res.data.children && res.data.children.length && res.data.children[0].catValue || ''
            })
        }
    }
}
</script>

<style lang="less" scoped>
.add-product-container {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    font-size: 16px;
    .container-title {
        margin: auto;
        width: 1100px;
        @media screen and (max-width: 1440px){
            width: 100%;
            max-width: 1440px;
            min-width: 990px;
            padding: 0 15px;
        }
    }
    .container {
        font-size: 14px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        .add-content {
            width: 1100px;
            @media screen and (max-width: 1440px){
                width: 100%;
                max-width: 1440px;
                min-width: 990px;
                margin: 0 15px;
            }
            .content {
                .right {
                    text-align: right;
                    line-height: 32px;
                }
            }
            /deep/ .ant-card-head {
                background: #e9e9e9;
            }
            /deep/ .ant-col {
                margin-bottom: 12px;
            }
            .label-left {
                line-height: 32px;
                text-align: right;
            }
            .label-c {
                line-height: 32px;
                text-align: center;
            }
            /deep/ .ant-alert-with-description.ant-alert-no-icon {
                padding: 6px 16px;
            }
            /deep/ .ant-card {
                margin-bottom: 20px;
            }
            .l-card {
                position: relative;
                .title {
                    display: inline-block;
                    position: absolute;
                    top: -12px;
                    left: 16px;
                    background: #fff;
                    z-index: 1;
                    font-weight: bold;
                    padding: 0 12px;
                    color: #000;
                }
            }
            .add-property-item {
                width: 100%;
                height: 100%;
            }
            .l-flex {
                flex-wrap: wrap;
            }
            .add-image {
                height: 135px;
                user-select:none;
                cursor: pointer;
                text-align: center;
                i {
                    color: #cccccc;
                    line-height: 135px;
                    font-size: 42px;
                }
            }
            .product-img {
                border: 1px solid #e4e4e4;
                width: 150px;
                min-height: 135px;
                margin-right: 20px;
                margin-bottom: 20px;
                .img-config {
                    text-align: center;
                    border-top: 1px solid #e4e4e4;
                    background: #f9fafc;
                    line-height: 36px;
                    font-size: 12px;
                }
                img {
                    width: 100%;
                    height: 135px;
                }
                // &:nth-child(-n+5) {
                //     margin-top: 0;
                // }
            }
            .advanced {
                padding: 0 16px;
            }
            .issue-shop {
                margin: 32px 0;
                text-align: center;
                button {
                    width: 148px;
                    font-weight: bold;
                }
            }
        }
        .help-content {
            width: 270px;
            margin-left: 20px;
        }
    }
}
/deep/ .ant-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-number-slot {
    background: #f2f2f2;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    // padding: 0 8px;
    border: 1px solid #d9d9d9;
    border-left: none;
    border-radius: @border-radius-base;
    /deep/ .ant-select-selection {
        background-color: transparent;
        border: none;
    }
    /deep/ .ant-select-open .ant-select-selection {
        border: none;
        box-shadow: none;
    }
    /deep/ .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
        border: none;
        box-shadow: none;
    }
}
.no-show-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
/deep/ .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 30px;
}
.font-error {
    color: @error;
}
.is-main {
    border: 1px solid @error !important;
}
</style>